import { AbstractControl, ValidatorFn } from '@angular/forms';

export class CheckboxValidator {
  static min(min: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value || [];
      if (value.length < min) {
        return { checkboxMin: { requiredLength: min, actualLength: value.length } };
      }
      return null;
    };
  }

  static max(max: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value || [];
      if (value.length > max) {
        return { checkboxMax: { requiredLength: max, actualLength: value.length } };
      }
      return null;
    };
  }
}
