import {TranslationInterface} from '../interfaces/translation.interface';
import {FormFieldValue} from './form-field-value';
import {FFUiStyleOption, FormFieldUiConfig} from './form-field-ui-config';
import {ValidatorFn, Validators} from '@angular/forms';
import {CheckboxValidator} from './../validators/checkbox-validator';

export class FormField {
  field_value: FormFieldValue;
  id: string;
  order: number;
  read_only: boolean;
  required: boolean;
  visible: boolean;
  evaluation_required: boolean;
  label: TranslationInterface;
  description: TranslationInterface;
  ui_config: FormFieldUiConfig;

  constructor(item?) {
    if (item) {
      this.fillFromJson(item);
    }
  }

  fillFromJson(item) {
    this.id = item.id;
    this.order = item.order || 0;
    this.required = item.required;
    this.read_only = item.read_only;
    this.visible = item.visible;
    this.evaluation_required = item.evaluation_required;
    this.label = item.label || '';
    this.field_value = new FormFieldValue(item.field_value);
    this.description = item.description;

    if (item?.ui_config && Object.keys(item?.ui_config)?.length > 0) {
      this.ui_config = new FormFieldUiConfig(item.ui_config);
    }
  }

  get translationKey(): string {
    return `shared.${this.label?.region}.${this.label?.key}`;
  }

  get validators(): ValidatorFn[] {
    const validators = [];

    if (this.required && this.visible) {
      validators.push(Validators.required);
    }

    if (this.field_value.min) {
      validators.push(Validators.min(this.field_value.min));
    }

    if (this.field_value.max) {
      validators.push(Validators.max(this.field_value.max));
    }

    if (this.field_value.multi_select_min) {
      validators.push(CheckboxValidator.min(this.field_value.multi_select_min));
    }

    if (this.field_value.multi_select_max) {
      validators.push(CheckboxValidator.max(this.field_value.multi_select_max));
    }

    if (this.field_value.min_length) {
      validators.push(Validators.minLength(this.field_value.min_length));
    }

    if (this.field_value.max_length) {
      validators.push(Validators.maxLength(this.field_value.max_length));
    }

    if (this.ui_config?.ui_style === FFUiStyleOption.PICKER_WHEEL) {
      validators.push(Validators.pattern(/^-?\d+$/));
    }

    return validators;
  }
}
